import React from "react";
import { Button, Form, Stack } from "react-bootstrap";
import { MdFlight } from "react-icons/md";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import PageTitle from "../../layouts/PageTitle";
import styles from "./styles/Arrivalgroup.module.css";

import { useDispatch, useSelector } from "react-redux";
import {
  getSingleArrivalDepartureGroupData,
  removePlayerFromPlan,
} from "../../../store/actions/TravelAdminActions/TravelAdminActions";
import { FaTrain, FaBus, FaCar } from "react-icons/fa";

export const formatOFTicketRegex = /.(jpe?g|png)$/i;

function ArrivalAndDepartureGroups() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  let travelType = location?.pathname.split("/")[1];

  const { singleArrivalAndDepartureData = {} } = useSelector(
    (store) => store?.TravelAdminReducer
  );

  React.useEffect(() => {
    dispatch(getSingleArrivalDepartureGroupData("arrival", id));
    return () => {};
  }, [dispatch, id]);

  const handleOnChange = () => {};

  //const handle remove player
  const handleRemovePlayer = (playerDetails) => {
    let { member_id } = playerDetails;

    swal({
      title: "Are you sure?",
      text: "",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      let res =
        willDelete && (await dispatch(removePlayerFromPlan(id, member_id)));
      if (res) {
        if (res?.success) {
          await swal(res?.message || "Operation was successful", {
            icon: "success",
          });
          let response = await dispatch(
            getSingleArrivalDepartureGroupData("arrival", id)
          );

          if (response?.data?.message === "Travelling plan not found") {
            navigate(-1);
          }
        } else {
          swal(res?.payload?.message);
        }
      } else {
        swal("Your travelling plan is safe!");
      }
    });
  };
  const transportationIcons = {
    TRAIN: <FaTrain size={30} />,
    BUS: <FaBus size={30} />,
    FLIGHT: <MdFlight size={30} />,
    CAR: <FaCar size={30} />,
    CAB: <FaCar size={30} />,
  };
  return (
    <>
      <PageTitle
        historyPopper={() => navigate(-1)}
        motherMenu={
          travelType === "arrival" ? "Arrival Groups" : "Departure Groups"
        }
        activeMenu={"List"}
        Button={() => (
          <Button onClick={() => navigate(-1)} className="mb-2" size="sm">
            Go Back
          </Button>
        )}
      />
      <div className="row align-items-stretch">
        {/* Left Card - Form */}
        <div className="col-lg-6">
          <div className="card">
            <div className={`card-header ${styles.customCardHeader}`}>
              Arrival Groups
            </div>
            <div
              className={`card-header justify-content-center align-items-center ${styles.customCardSubHeader}`}
            >
              <span
                className="d-flex justify-content-center align-items-center "
                style={{ gap: 2 }}
              >
                {
                  transportationIcons[
                    singleArrivalAndDepartureData?.transportation_mode
                  ]
                }
                <span className="ml-2 fs-3">
                  {singleArrivalAndDepartureData?.transportation_mode}
                </span>
              </span>
            </div>
            <div className={`card-body ${styles.customCardBody}`}>
              {/* Form Section */}
              <Form>
                {/* Name Input */}
                <Form.Group className="mb-3">
                  <Form.Label>Department Name</Form.Label>
                  <Form.Control
                    onChange={handleOnChange}
                    disabled
                    value={singleArrivalAndDepartureData?.department}
                    type="text"
                    placeholder="Chinese Taipei Team"
                  />
                </Form.Group>
                {/* Federation Dropdown */}
                <Form.Group className="mb-3">
                  <Form.Label>Federation</Form.Label>
                  <Form.Select
                    onChange={handleOnChange}
                    value={singleArrivalAndDepartureData?.federation}
                  >
                    <option value={singleArrivalAndDepartureData?.federation}>
                      {singleArrivalAndDepartureData?.federation}
                    </option>
                    {/* Add more options as needed */}
                  </Form.Select>
                </Form.Group>
                {/* Arrival And Departure Date */}
                <Form.Group className="mb-3">
                  <Form.Label>
                    {travelType === "arrival"
                      ? "Arrival Date - DD/MM/YYYY"
                      : "Departure Date - DD/MM/YYYY"}
                  </Form.Label>
                  <Stack direction="horizontal" gap={2}>
                    <Form.Control
                      disabled
                      value={
                        travelType === "arrival"
                          ? new Date(
                              singleArrivalAndDepartureData?.date_of_arrival
                            ).getDate()
                          : new Date(
                              singleArrivalAndDepartureData?.date_of_departure
                            ).getDate()
                      }
                      onChange={handleOnChange}
                      type="text"
                      placeholder="DD"
                      maxLength={2}
                    />
                    <Form.Control
                      disabled
                      value={
                        travelType === "arrival"
                          ? new Date(
                              singleArrivalAndDepartureData?.date_of_arrival
                            ).getMonth() + 1
                          : new Date(
                              singleArrivalAndDepartureData?.date_of_departure
                            ).getMonth() + 1
                      }
                      onChange={handleOnChange}
                      type="text"
                      placeholder="MM"
                      maxLength={2}
                    />
                    <Form.Control
                      disabled
                      value={
                        travelType === "arrival"
                          ? new Date(
                              singleArrivalAndDepartureData?.date_of_arrival
                            ).getFullYear() + 1
                          : new Date(
                              singleArrivalAndDepartureData?.date_of_departure
                            ).getFullYear() + 1
                      }
                      onChange={handleOnChange}
                      type="text"
                      placeholder="YYYY"
                      maxLength={4}
                    />
                  </Stack>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    {" "}
                    {travelType === "arrival"
                      ? "Arrival time"
                      : "Departure time"}
                  </Form.Label>
                  {/* {console.log(
                    "singleArrivalAndDepartureData",
                    singleArrivalAndDepartureData
                  )} */}
                  <Stack direction="horizontal" gap={2}>
                    <Form.Control
                      disabled
                      value={
                        travelType === "arrival"
                          ? singleArrivalAndDepartureData?.arrival_time?.split(
                              ":"
                            )[0]
                          : singleArrivalAndDepartureData?.departure_time?.split(
                              ":"
                            )[0]
                      }
                      onChange={handleOnChange}
                      type="text"
                      placeholder="07"
                      maxLength={2}
                    />
                    <Form.Control
                      disabled
                      value={
                        travelType === "arrival"
                          ? singleArrivalAndDepartureData?.arrival_time?.split(
                              ":"
                            )[1]
                          : singleArrivalAndDepartureData?.departure_time?.split(
                              ":"
                            )[1]
                      }
                      onChange={handleOnChange}
                      type="text"
                      placeholder="20"
                      maxLength={2}
                    />
                  </Stack>
                </Form.Group>
                {/* Arrival And Departure location */}
                <Form.Group className="mb-3">
                  <Form.Label>
                    {travelType === "arrival"
                      ? "Arrival Location"
                      : "Departure Location"}
                  </Form.Label>

                  <Form.Control
                    disabled
                    value={
                      travelType === "arrival"
                        ? `${singleArrivalAndDepartureData?.arrival_location}`
                        : `${singleArrivalAndDepartureData?.departure_location}`
                    }
                    type="text"
                    placeholder=""
                  />
                </Form.Group>
                {/* Airline */}
                {/* <Form.Group className="mb-3">
                  <Form.Label>Airline</Form.Label>
                  <Form.Control type="text" placeholder="China Airlines (CI)" />
                </Form.Group> */}
                {/* Flight Number */}
                <Form.Group className="mb-3">
                  <Form.Label>
                    {singleArrivalAndDepartureData?.transportation_mode} Number
                  </Form.Label>
                  <Form.Control
                    onChange={handleOnChange}
                    disabled
                    value={singleArrivalAndDepartureData?.vehical_no}
                    type="text"
                    placeholder="75"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  {/* <Form.Label>
                    {singleArrivalAndDepartureData?.doc_path
                      ? formatOFTicketRegex.test(
                          singleArrivalAndDepartureData?.doc_path
                        )
                        ? "View Image"
                        : "View PDF"
                      : "N/A"}
                  </Form.Label> */}

                  <div>
                    {/* {singleArrivalAndDepartureData?.doc_path ? (
                      formatOFTicketRegex.test(
                        singleArrivalAndDepartureData?.doc_path
                      ) ? (
                        <button
                          type="button"
                          className="btn btn-sm btn-primary"
                        >
                          View Image
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-sm btn-primary"
                        >
                          View PDF
                        </button>
                      )
                    ) : (
                      "N/A"
                    )} */}

                    {singleArrivalAndDepartureData?.doc_path ? (
                      <button
                        type="button"
                        className="btn btn-sm btn-primary"
                        onClick={() =>
                          window.open(
                            `${process.env.REACT_APP_BASE_URL}${singleArrivalAndDepartureData?.doc_path}`,
                            "_blank"
                          )
                        }
                      >
                        View Ticket
                      </button>
                    ) : (
                      <button
                        disabled
                        type="button"
                        className="btn btn-sm btn-primary"
                      >
                        View Ticket
                      </button>
                    )}
                  </div>
                </Form.Group>
              </Form>
            </div>
          </div>
        </div>

        {/* Right Section - People List */}
        <div className="col-lg-6">
          <div className="card w-100">
            <div className={`card-header ${styles.customCardHeader}`}>
              <span> People</span>
            </div>
            <div
              className={`card-body ${styles.customCardBodyOfTable} overflow-auto`}
            >
              <table className={`table ${styles.customeTable}`}>
                <thead>
                  <tr>
                    <th>Player ID</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Accreditation</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {singleArrivalAndDepartureData?.members?.map(
                    (item, index) => (
                      <tr key={index}>
                        <td>{item.member_id}</td>
                        <td>{item.first_name}</td>
                        <td>{item.last_name}</td>
                        <td>
                          <span className={`badge bg-primary`}>
                            {item.sub_category_name_view?.toUpperCase()}
                          </span>
                        </td>
                        <td>
                          <span
                            className={`badge bg-danger`}
                            onClick={(e) => handleRemovePlayer(item)}
                          >
                            REMOVE
                          </span>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ArrivalAndDepartureGroups;
