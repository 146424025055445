export const city = [
  {
    cityName: "Almora",
    BoardingPoints: [
      "Kathgodam Railway Station",
      "Rudrapur Railway Station",
      "Haldwani Railway Station",
      "Haldwani Bus Station",
    ],
  },
  {
    cityName: "Bhimtal",
    BoardingPoints: [
      "Kathgodam Railway Station",
      "Lal Kuan Railway Station",
      "Haldwani Railway Station",
      "Haldwani Bus Station",
    ],
  },
  {
    cityName: "Dehradun",
    BoardingPoints: [
      "Dehradun Airport",
      "Dehradun Railway Station",
      "Dehradun Bus Station",
      "Rishikesh Railway Station",
    ],
  },
  {
    cityName: "Haldwani",
    BoardingPoints: [
      "Pant Nagar Airport",
      "Haldwani Railway Station",
      "Haldwani Bus Station",
      "Lal Kuan Railway Station",
    ],
  },
  {
    cityName: "Haridwar",
    BoardingPoints: [
      "Haridwar Railway Station",
      "Haridwar Bus Station",
      "Dehradun Airport",
    ],
  },
  {
    cityName: "Khatima",
    BoardingPoints: ["Khatima Bus Station", "Tanakpur Railway Station"],
  },
  {
    cityName: "Koti Colony Tehri",
    BoardingPoints: [
      "Tehri Bus Station",
      "Rishikesh Railway Station",
      "Haridwar Railway Station",
      "Dehradun Airport",
      "Dehradun Railway Station",
    ],
  },
  {
    cityName: "Pithoragarh",
    BoardingPoints: [
      "Naini-Saini Airport",
      "Pithoragarh Bus Station",
      "Pant Nagar Airport",
    ],
  },
  {
    cityName: "Rudrapur",
    BoardingPoints: [
      "Pant Nagar Airport",
      "Rudrapur Railway Station",
      "Rudrapur Bus Station",
    ],
  },
  {
    cityName: "Shiv Puri Rishikesh",
    BoardingPoints: [
      "Rishikesh Railway Station",
      "Rishikesh Bus Station",
      "Dehradun Airport",
    ],
  },
  {
    cityName: "Tanakpur",
    BoardingPoints: ["Tanakpur Railway Station", "Tanakpur Bus Station"],
  },
];
