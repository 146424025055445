import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Swal from "sweetalert2";
import { Spinner } from "react-bootstrap"; // Importing Spinner from React Bootstrap
import {
  sync,
  syncall,
} from "../../../services/api/TravelAdminServiceFunc/TravelAdminServiceFunc";

const Buttons = [
  {
    title: "Sync All",
    variant: "primary",
    func: async (setResponse, setLoading) => {
      try {
        setLoading(true); // Start loading
        const res = await syncall();

        if (res.data.success) {
          Swal.fire({
            title: "Sync Successful",
            text: `${res.data.data.total_count} records have been synced.`,
            icon: "success",
          });
        } else {
          Swal.fire({
            title: "Sync Failed",
            text: res.message || "An unknown error occurred.",
            icon: "error",
          });
        }
        setResponse(res);
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: error.message || "Failed to sync data.",
          icon: "error",
        });
      } finally {
        setLoading(false); // Stop loading
      }
    },
  },
  // {
  //   title: "Sync",
  //   variant: "primary",
  //   func: async (setResponse, setLoading) => {
  //     try {
  //       setLoading(true); // Start loading
  //       const res = await sync();

  //       if (res.data.success) {
  //         Swal.fire({
  //           title: "Sync Successful",
  //           text: `${res.data.data.total_count} records have been synced.`,
  //           icon: "success",
  //         });
  //       } else {
  //         Swal.fire({
  //           title: "Sync Failed",
  //           text: res.message || "An unknown error occurred.",
  //           icon: "error",
  //         });
  //       }
  //       setResponse(res);
  //     } catch (error) {
  //       Swal.fire({
  //         title: "Error",
  //         text: error.message || "Failed to sync data.",
  //         icon: "error",
  //       });
  //     } finally {
  //       setLoading(false); // Stop loading
  //     }
  //   },
  // },
];

function SyncingButton() {
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false); // Loader state

  return (
    <div>
      {Buttons.map((button, index) => (
        <Button
          key={index}
          variant={button.variant}
          className="me-2"
          onClick={() => button.func(setResponse, setLoading)}
          disabled={loading}
        >
          {loading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="me-2"
            />
          ) : null}
          {button.title}
        </Button>
      ))}
    </div>
  );
}

export default SyncingButton;
