import React, { useEffect } from "react";
import PageTitle from "../../layouts/PageTitle";
import ArrivalModesCard from "./ArrivalModesCard";
import { Button } from "react-bootstrap";
import { MdFlight } from "react-icons/md";
import { FaBus, FaCar, FaTrain, FaEye } from "react-icons/fa";
import GenericTable from "./Table";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import swal from "sweetalert";

import { useNavigate } from "react-router-dom";

import {
  getAllPlayers,
  getDataOfArrivalAndDeparture,
  getNSFList,
  getSOAList,
} from "../../../store/actions/TravelAdminActions/TravelAdminActions";
import { useForm } from "react-hook-form";
import { deleteTravellingPlan } from "../../../store/actions/ContingentTravelActions/ContingentTravelAction";
import PaginationComp from "../pagination/PaginationComp";
import SyncingButton from "../SyncingButton/SyncingButton";
import ExcelButton from "../ExcelComp/ExcelButton";

const transportModes = [
  { id: 1, title: "FLIGHT", icon: <MdFlight size={20} /> },
  { id: 2, title: "TRAIN", icon: <FaTrain size={20} /> },
  { id: 3, title: "BUS", icon: <FaBus size={20} /> },
  { id: 4, title: "CAR", icon: <FaCar size={20} /> },
  // { id: 5, title: "OTHERS", icon: <SiTransportforlondon size={20} /> },
  { id: 0 },
];

const actions = [
  {
    label: "View Details",
    className: "btn-primary",
    icon: <FaEye />,
    onClick: (e, item, navigate) => {
      e.stopPropagation();
      navigate(`${item.id}`);
    },
  },
  // {
  //   label: "Delete",
  //   className: "btn-danger",
  //   icon: <MdDelete />,
  //   onClick: handleDelete,
  // },
];

const columns = [
  { label: "S.No", field: "s_no" },
  { label: "Federation", field: "federation" },
  { label: "Department Name", field: "department" },
  { label: "Type", field: "transportation_mode" },
  { label: "Departure Date", field: "date" },
  { label: "Departure Time", field: "time" },
  // { label: "Infos", field: "infos" },
  { label: "Size", field: "size_of_team" },
  // { label: "URL", field: "url" },
  { label: "Ticket", field: "doc_path" },
  { label: "Actions", field: "actions" },
];

export function handleDelete(e, item, dispatch) {
  e.stopPropagation();
  swal({
    title: "Are you sure?",
    text: "Once deleted, you will not be able to recover this imaginary file!",
    icon: "warning",
    buttons: true,
    dangerMode: true,
  }).then(async (willDelete) => {
    let res = willDelete && (await dispatch(deleteTravellingPlan(item.id)));
    if (res) {
      if (res?.success) {
        await swal(res?.message || "Operation was successful", {
          icon: "success",
        });
        await dispatch(getDataOfArrivalAndDeparture("departure"));
      } else {
        swal(res?.payload?.message);
      }
    } else {
      swal("Your travelling plan is safe!");
    }
  });
}

function TravelModuleAdminDeparture() {
  const [activeLink, setactiveLink] = React.useState(
    transportModes[transportModes.length - 1]?.id
  );
  const [persistFilterValuesData, setpersistFilterValuesData] = React.useState(
    {}
  );
  const [currentPage, setcurrentPage] = React.useState(1);
  const [totalPage, settotalPage] = React.useState(1);

  const [transportTypeModule, setTransportTypeModule] = React.useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { register, handleSubmit, reset } = useForm();
  const [selectedSOA, setSelectedSOA] = React.useState("");
  const [selectedNSF, setSelectedNSF] = React.useState("");
  const [toggleForFilter, settoggleForFilter] = React.useState("");
  const [totalDeparturePlans, settotalDeparturePlans] = React.useState(null);

  const handlePaginationRelatedStates = (res) => {
    setcurrentPage(res?.payload?.data?.pagination?.currentPage);
    settotalPage(res?.payload?.data?.pagination?.totalPages);
    settotalDeparturePlans(res?.payload?.data?.pagination?.total);
  };
  const {
    countryList = [],
    departureData = [],
    SOAList = [],
    NSFList = [],
  } = useSelector((store) => store?.TravelAdminReducer);

  const handleActiveBox = (ele) => {
    let { title } = ele;
    dispatch(
      getDataOfArrivalAndDeparture("departure", {
        transportMode: title,
      })
    );
    setTransportTypeModule({ transportMode: title });
    setactiveLink(ele?.id);
  };

  useEffect(() => {
    // dispatch(getCountryList());
    dispatch(getAllPlayers);
    // dispatch();
    (async () => {
      let res = await dispatch(
        getDataOfArrivalAndDeparture("departure", { page: currentPage })
      );
      handlePaginationRelatedStates(res);
    })();
    dispatch(getSOAList());
    dispatch(getNSFList());
  }, []);
  //
  // const handleFilterValue = (data) => {
  //   let { transportMode } = transportTypeModule;
  //   data.transportMode = transportMode;
  //   data.soa_id = selectedSOA;
  //   // dispatch(getDataOfArrivalAndDeparture("departure", data));
  //   setpersistFilterValuesData({ ...data });
  //   (async () => {
  //     let res = await dispatch(
  //       getDataOfArrivalAndDeparture("departure", { ...data, page: 1 })
  //     );
  //     handlePaginationRelatedStates(res);
  //   })();
  // };
  const handleFilterValue = (data) => {
    let { transportMode } = transportTypeModule;
    data.transportMode = transportMode;

    // Conditionally set either soa_id or nsf_id
    if (toggleForFilter === "soa") {
      data.soa_id = selectedSOA;
      // Reset NSF-related data
      data.nsf_id = "";
      setSelectedNSF("");
    } else if (toggleForFilter === "nsf") {
      data.nsf_id = selectedNSF;
      // Reset SOA-related data
      data.soa_id = "";
      setSelectedSOA("");
    }

    setpersistFilterValuesData({ ...data });

    (async () => {
      let { payload = {} } = await dispatch(
        getDataOfArrivalAndDeparture("arrival", { ...data, page: 1 })
      );
      let { data: resData = {} } = payload;
      let { pagination = {} } = resData;
      setcurrentPage(pagination.currentPage);
      settotalPage(pagination.totalPages);
    })();
  };

  const trOnClickEvent = (element) => {
    if (element && element.id) {
      navigate(`/departure/${element.id}`);
    } else {
      console.error("Element or ID is undefined");
    }
  };
  const handlePagination = async (number) => {
    setcurrentPage(number);
    let res = await dispatch(
      getDataOfArrivalAndDeparture("departure", {
        ...persistFilterValuesData,
        page: number,
      })
    );
    handlePaginationRelatedStates(res);
  };
  const handleCheckValue = (e) => {
    settoggleForFilter(e.target.value);
  };
  return (
    <>
      <PageTitle
        motherMenu={"Transportation List"}
        activeMenu={"Departure"}
        Button={() => <SyncingButton />}
      />
      <ArrivalModesCard
        transportModes={transportModes}
        activeLink={activeLink}
        handleActiveBox={handleActiveBox}
      />

      <div className="card mt-4">
        <div className="card-body">
          <form onSubmit={handleSubmit(handleFilterValue)}>
            <div className="row">
              {/* Name Label */}

              <div className="col-lg-2">
                <label>Filter By</label>
                <select className="form-control" onChange={handleCheckValue}>
                  <option value="">Select Filter Type </option>
                  <option value="soa">SOA</option>
                  <option value="nsf">NSF</option>
                </select>
              </div>
              {toggleForFilter === "nsf" ? (
                <div className="col-lg-2">
                  <label>NSF</label>
                  <select
                    className="form-control"
                    {...register("nsf_id", {})}
                    value={selectedNSF}
                    onChange={(e) => setSelectedNSF(e.target.value)}
                  >
                    <option value="">Select NSF </option>

                    {NSFList.map((item, index) => {
                      return (
                        <option key={uuidv4()} value={item?.nsf_id}>
                          {item?.fullname}
                        </option>
                      );
                    })}
                  </select>
                </div>
              ) : toggleForFilter === "soa" ? (
                <div className="col-lg-2">
                  <label>SOA</label>
                  <select
                    className="form-control"
                    {...register("soa_id", {})}
                    value={selectedSOA}
                    onChange={(e) => setSelectedSOA(e.target.value)}
                  >
                    <option value="">Select SOA</option>
                    {SOAList.map((item, index) => {
                      return (
                        <option key={uuidv4()} value={item?.soa_id}>
                          {item?.fullname}
                        </option>
                      );
                    })}
                  </select>
                </div>
              ) : null}

              {/* Departure Date Label */}
              <div className="col-lg-2">
                <div className="row">
                  <div className="form-group mb-3 col-lg-12">
                    <label>Date of Departure</label>
                    <input
                      {...register("departure_date", {})}
                      type="date"
                      className="form-control"
                    />

                    <p className="text-danger"></p>
                  </div>
                </div>
              </div>

              {/* Time Input */}
              <div className="col-lg-2">
                <label> Departure Time</label>
                <input
                  {...register("departure_time", {})}
                  type="time"
                  className="form-control"
                />
              </div>

              {/* Reset and Apply Buttons */}
              <div className="col-lg-2">
                <label> Actions</label>
                <div className="row">
                  <div className="col-lg-6">
                    <button
                      onClick={() => {
                        reset();
                        setSelectedSOA("");
                        setpersistFilterValuesData({});
                        setactiveLink(
                          transportModes[transportModes.length - 1]?.id
                        );
                        setTransportTypeModule({});
                        dispatch(getDataOfArrivalAndDeparture("departure", {}));
                        (async () => {
                          let res = await dispatch(
                            getDataOfArrivalAndDeparture("departure", {
                              page: 1,
                            })
                          );
                          handlePaginationRelatedStates(res);
                        })();
                      }}
                      type="button"
                      className="btn btn-outline-danger bg-transparent  text-danger border-danger"
                    >
                      Reset
                    </button>
                  </div>
                  <div className="col-lg-6">
                    <Button type={"submit"}>Apply</Button>
                  </div>
                </div>
              </div>
              <div className="col-lg-2"></div>
              <div className="col-lg-2">
                <label></label>
                <div className="row  justify-content-end">
                  {/* <div className="col-lg-6"> */}
                  <ExcelButton travelType="departure" />
                </div>
                {/* </div> */}
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* Table */}
      <div className="row mb-2">
        <div className="col-md-3">
          <span>
            <b> Total Departure Plans</b> : {totalDeparturePlans}
          </span>
        </div>
      </div>
      <GenericTable
        columns={columns}
        data={departureData}
        actions={actions}
        trOnClickEvent={trOnClickEvent}
        dispatch={dispatch}
        currentPage={currentPage || 1}
        PaginationComp={
          <PaginationComp
            handlePagination={handlePagination}
            currentpage={currentPage}
            setcurrentPage={setcurrentPage}
            size={totalPage}
            gutter={true}
            variant={""}
            bg={true}
            circle={false}
          />
        }
      />
    </>
  );
}

export default TravelModuleAdminDeparture;
