import React from "react";
import { FaTrain, FaBus, FaCar } from "react-icons/fa";
import { MdFlight } from "react-icons/md";
import { HiOutlineUserGroup } from "react-icons/hi";
import { SiTransportforlondon } from "react-icons/si";
import { Link, useNavigate } from "react-router-dom";
import { Badge } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import { formatOFTicketRegex } from "../Contingent/TravelPlan";

const GenericTable = ({
  columns,
  data = [],
  actions,
  setcurrentPage,
  currentPage,
  trOnClickEvent = () => {},
  dispatch = () => {},
  PaginationComp = null,
  LimitsSelectBox = () => {
    return <></>;
  },
}) => {
  const navigate = useNavigate();
  return (
    <div className="col-12">
      <div className="card">
        <div className="card-body">
          <LimitsSelectBox />
          <div className="w-100 table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  {columns.map((column, index) => (
                    <th key={uuidv4()}>{column.label}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data && data.length > 0 ? (
                  data.map((item, index) => (
                    <tr
                      key={uuidv4()}
                      onClick={() => trOnClickEvent(item)}
                      style={{ cursor: "pointer" }}
                    >
                      {columns.map((column, colIndex) => {
                        if (column.field === "organisation") {
                          return (
                            <td key={uuidv4()}>
                              <Link to={`${item.id}`}>
                                {item[column.field]}
                              </Link>
                            </td>
                          );
                        } else if (column.field === "transportation_mode") {
                          return (
                            <td key={uuidv4()}>
                              <div className="d-flex justify-content-center align-items-center gap-2">
                                {item.transportation_mode === "TRAIN" ? (
                                  <FaTrain />
                                ) : item.transportation_mode === "BUS" ? (
                                  <FaBus />
                                ) : item.transportation_mode === "FLIGHT" ? (
                                  <MdFlight />
                                ) : item.transportation_mode === "CAR" ? (
                                  <FaCar />
                                ) : item.transportation_mode === "CAB" ? (
                                  <FaCar />
                                ) : (
                                  <SiTransportforlondon />
                                )}
                                {item.transportation_mode}
                              </div>
                            </td>
                          );
                        } else if (column.field === "actions") {
                          return (
                            <td key={uuidv4()}>
                              {actions && actions.length > 0 ? (
                                <div className="d-flex gap-2">
                                  {actions.map((action, actionIndex) => (
                                    <button
                                      key={uuidv4()}
                                      className={`btn btn-sm ${action.className}`}
                                      onClick={(e) => {
                                        action.onClick(
                                          e,
                                          item,
                                          navigate,
                                          dispatch
                                        );
                                      }}
                                    >
                                      {action.icon} {action.label}
                                    </button>
                                  ))}
                                </div>
                              ) : (
                                "No Actions"
                              )}
                            </td>
                          );
                        } else if (column.field === "size") {
                          return (
                            <td key={uuidv4()}>
                              <span className="badge badge-primary d-flex align-items-center justify-content-between">
                                <HiOutlineUserGroup size={18} /> {item.size}
                              </span>
                            </td>
                          );
                        } else if (column.field === "s_no") {
                          return (
                            <td key={uuidv4()}>
                              {(currentPage - 1) * 10 + index + 1}
                            </td>
                          );
                        } else if (column.field === "url") {
                          return (
                            <td key={uuidv4()}>
                              <Link to={`${item[column.field]}`}>URL</Link>
                            </td>
                          );
                        } else if (column.field === "doc_path") {
                          return (
                            <td key={uuidv4()}>
                              {item?.doc_path ? (
                                formatOFTicketRegex.test(item?.doc_path) ? (
                                  <img
                                    src={`${process.env.REACT_APP_BASE_URL}${item?.doc_path}`}
                                    alt={item?.department}
                                    style={{ width: "100px", height: "auto" }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      window.open(
                                        `${
                                          process.env.REACT_APP_BASE_URL +
                                          item?.doc_path
                                        }`,
                                        "_blank"
                                      );
                                    }}
                                  />
                                ) : (
                                  <a
                                    href={`${process.env.REACT_APP_BASE_URL}${item?.doc_path}`}
                                    target="_blank"
                                    reel="noopener noreferrer"
                                    onClick={(e) => e.stopPropagation()}
                                  >
                                    View PDF
                                  </a>
                                )
                              ) : (
                                "N/A"
                              )}
                            </td>
                          );
                        } else if (column.field === "status") {
                          return (
                            <td key={uuidv4()}>
                              <Badge
                                pill
                                bg={`${
                                  item[column.field] === "inactive"
                                    ? "danger"
                                    : "success"
                                } `}
                              >
                                {item[column.field]}
                              </Badge>
                            </td>
                          );
                        } else if (column.field === "date") {
                          return (
                            <td key={uuidv4()}>
                              {new Date(item.date).toLocaleString("en-US", {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                                // hour: "2-digit",
                                // minute: "2-digit",
                              })}
                            </td>
                          );
                        } else if (column.field === "time") {
                          return (
                            <td key={uuidv4()}>
                              {new Date(
                                `1970-01-01T${item.time}`
                              ).toLocaleString("en-US", {
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: true,
                              })}
                            </td>
                          );
                        } else {
                          return <td key={uuidv4()}>{item[column.field]}</td>;
                        }
                      })}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={columns.length} className="text-center">
                      Data is not available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="d-flex justify-content-center p-0 m-0">
              {PaginationComp && PaginationComp}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenericTable;
