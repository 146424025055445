import React, { useEffect } from "react";
import PageTitle from "../../layouts/PageTitle";
import Calendar from "./CalendarComp";
import { Stack } from "react-bootstrap";
import styles from "./styles/TravelModuleAdminCalendar.module.css";
import { FaPlaneArrival } from "react-icons/fa";
import { FaPlaneDeparture } from "react-icons/fa6";
import { getCalendarData } from "../../../store/actions/TravelAdminActions/TravelAdminActions";
import { useDispatch, useSelector } from "react-redux";
import SyncingButton from "../SyncingButton/SyncingButton";

const Modes = [
  { id: 1, title: "Arrival", icon: <FaPlaneArrival size={15} /> },
  { id: 2, title: "Departure", icon: <FaPlaneDeparture size={15} /> },
];

const initialTransportMode = { mode: "arrival", transportation_mode: "" };

function TravelModuleAdminCalendar() {
  //year
  const [currentYear, setCurrentYear] = React.useState(
    new Date().getFullYear()
  );

  //redux
  const dispatch = useDispatch();
  const { calendarData } = useSelector((store) => store?.TravelAdminReducer);
  const [transportMode, setTransportMode] =
    React.useState(initialTransportMode);
  const [activeLink, setActiveLink] = React.useState(Modes[0].id);
  // const [events, setEvents] = React.useState(eventsData);
  const [selectBoxValue, setselectBoxValue] = React.useState("");
  const handleActiveBox = (selectedItem) => {
    setTransportMode({ mode: selectedItem.title?.toLowerCase() });
    setActiveLink(selectedItem?.id);
  };

  //get the calendar data
  useEffect(() => {
    dispatch(
      getCalendarData({
        ...transportMode,
        transportation_mode: selectBoxValue,
        currentYear,
      })
    );
  }, [transportMode, selectBoxValue, currentYear, dispatch]);

  const handleChange = (e) => {
    setselectBoxValue(e.target.value);
  };

  const handleYear = (newDate) => {
    const newYear = newDate.getFullYear();
    if (newYear !== currentYear) {
      setCurrentYear(newYear);
    }
  };
  return (
    <>
      <PageTitle
        motherMenu={"Transportation"}
        activeMenu={"Transport"}
        Button={() => <SyncingButton />}
      />
      <div className="mb-2 d-flex flex-wrap gap-2 align-items-center ">
        {Modes?.map((ele) => {
          return (
            <div
              key={ele.id}
              className={`${styles.box}`}
              style={{
                backgroundColor: "var(--primary-light)",
                backgroundImage:
                  activeLink === ele.id
                    ? "linear-gradient(to right, var(--primary), var(--primary))"
                    : "linear-gradient(to right, var(--primary-light), var(--primary-dark))",
                backgroundSize: activeLink === ele.id ? "100% 100%" : "0% 100%",
                backgroundRepeat: "no-repeat",
                transition: "background-size 0.5s ease",
              }}
              onClick={() => handleActiveBox(ele)}
            >
              <Stack
                className="justify-content-center align-items-center gap-2"
                direction="horizontal"
              >
                <div>{ele?.icon}</div>
                <div>{ele.title}</div>
              </Stack>
            </div>
          );
        })}
        <div className="">
          <select
            defaultValue={selectBoxValue}
            onChange={handleChange}
            className={`form-control ${styles.customSelectBox}`}
            id="val-skill"
            name="val-skill"
          >
            <option value="">Please select</option>
            <option value="FLIGHT">Flight</option>
            <option value="TRAIN">Train</option>
            <option value="BUS">Bus</option>
            <option value="CAR">Car</option>
            <option value="OTHERS">Others</option>
          </select>
        </div>
      </div>
      <Calendar events={calendarData} handleYear={handleYear} />
    </>
  );
}

export default TravelModuleAdminCalendar;
