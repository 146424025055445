import React from "react";
import { getAllExcelData } from "../../../store/actions/TravelAdminActions/TravelAdminActions";
import { useDispatch, useSelector } from "react-redux";
import { json2csv } from "json-2-csv";

function ExcelButton({ travelType }) {
  const dispatch = useDispatch();
  const { EXCELDATA = [] } = useSelector((store) => store?.TravelAdminReducer);

  const handleButtonClick = async () => {
    try {
      let { payload = {} } = await dispatch(getAllExcelData(travelType));
      if (payload.success) {
        // Convert the JSON data to CSV
        const csv = await json2csv(EXCELDATA);

        // Create a Blob from the CSV string
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

        // Create a link element
        const link = document.createElement("a");

        // If the browser is Internet Explorer
        if (navigator.msSaveBlob) {
          navigator.msSaveBlob(blob, "data.csv");
        } else {
          // Create a download link for other browsers
          link.href = URL.createObjectURL(blob);
          link.download = "data.csv"; // Set the file name for the downloaded file
          link.click(); // Simulate a click to start the download
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <>
      <button
        onClick={handleButtonClick}
        type="button"
        className="btn btn-outline-primary border-primary no-hover"
      >
        <i className="bi bi-file-earmark-excel"></i> Export to Excel
      </button>
    </>
  );
}

export default ExcelButton;
