import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import TravelPlan from "../../components/Contingent/TravelPlan";
import Error404 from "../../pages/Error404";

const routes = [
  { path: "/", element: <TravelPlan /> },
  { path: "/travel-plan", element: <TravelPlan /> },
  {
    path: "*",
    element: () => {
      return setTimeout(() => {
        return <Error404 />;
      }, 1000);
    },
  },
];

function ContingentRoutes({ defaultRoute }) {
  const navigate = useNavigate();

  return (
    <Routes>
      {routes.map(({ path, element }, index) => {
        return <Route key={index} path={path} element={element} />;
      })}
    </Routes>
  );
}

export default ContingentRoutes;
