import React, { useState } from "react";
import { Pagination } from "react-bootstrap";
import { Link } from "react-router-dom";

function PaginationComp({
  size,
  gutter,
  variant,
  bg,
  circle,
  setcurrentPage,
  currentpage,
  handlePagination,
}) {
  const maxButtons = 10; // Number of pagination buttons to show at a time

  // Calculate the start and end pages based on the active page and maxButtons
  let startPage = currentpage - Math.floor(maxButtons / 2);
  startPage = Math.max(startPage, 1); // Ensure startPage is at least 1

  let endPage = startPage + maxButtons - 1;
  if (endPage > size) {
    // If endPage exceeds the total size, shift the range back
    endPage = size;
    startPage = Math.max(1, endPage - maxButtons + 1);
  }

  // Generate the pagination items
  const items = [];
  for (let number = startPage; number <= endPage; number++) {
    items.push(
      <Pagination.Item
        key={number}
        onClick={() => {
          setcurrentPage(number);
          handlePagination(number);
        }}
        active={number === currentpage}
      >
        {number}
      </Pagination.Item>
    );
  }

  const handleNextButtonAndPrevButton = (direction) => {
    if (direction === "next" && currentpage < size) {
      setcurrentPage(currentpage + 1);
      handlePagination(currentpage + 1);
    } else if (direction === "previous" && currentpage > 1) {
      setcurrentPage(currentpage - 1);
      handlePagination(currentpage - 1);
    }
  };

  return (
    <Pagination
      size={size}
      className={`mt-4 ${gutter ? "pagination-gutter" : ""} ${
        variant && `pagination-${variant}`
      } ${!bg && "no-bg"} ${circle && "pagination-circle"}`}
    >
      {/* Previous button */}
      <li
        className="page-item page-indicator"
        onClick={() => handleNextButtonAndPrevButton("previous")}
      >
        <Link className="page-link" to="#">
          <i className="la la-angle-left" />
        </Link>
      </li>

      {/* Render page items */}
      {items}

      {/* Next button */}
      <li
        className="page-item page-indicator"
        onClick={() => handleNextButtonAndPrevButton("next")}
      >
        <Link className="page-link" to="#">
          <i className="la la-angle-right" />
        </Link>
      </li>
    </Pagination>
  );
}

export default PaginationComp;
