import React, { useEffect } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import MutliSelect from "../multiSelect/MutliSelect";
import Swal from "sweetalert2";

import {
  getTeamMember,
  getTravellingPlans,
  updateTravelData,
} from "../../../store/actions/ContingentTravelActions/ContingentTravelAction";
import { useSearchParams } from "react-router-dom";
import { city } from "./BoardingPoints";

const PREVENT_SPECIAL_CHARACTER = /[<>&"\\/:;(){}\[\]|*?#~$!^]/;

function EditTravelPlan({
  showEditTravelForm,
  closeEditTravelForm,
  travelData,
}) {
  const [searchParams] = useSearchParams();
  const [handleError, sethandleError] = React.useState({});

  //redux
  const dispatch = useDispatch();
  const [selectedMember, setSelectedMember] = React.useState(
    travelData?.member_ids || []
  );

  //
  const [selectedRole, setSelectedRole] = React.useState(
    travelData?.sub_category_type_id || []
  );

  const {
    clearErrors,
    setError,
    register,
    handleSubmit,
    getValues,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  let {
    roles = [],
    members = [],
    isLoading,
  } = useSelector((store) => store.travelreduer);

  // -----------------------------------
  //------------------------------------
  const travel_type = watch("travel_type");
  const genderValue = watch("gender");
  const userType = localStorage.getItem("userType");

  useEffect(() => {
    let defaultValues = {
      travel_type: travelData?.travel_type,
      department: travelData.department,
      transportation_mode: travelData.transportation_mode,
      poc_name: travelData.poc_name,
      poc_contact: travelData.poc_contact,
      gender: travelData.gender_id?.toString() || "",
      vehical_no: travelData?.vehical_no,
      memberSize: travelData?.size_of_team,
      file: "",
      pnr_number: travelData?.pnr_number,
      city: travelData?.city,
      boarding_point: travelData?.boarding_point,
    };

    if (travelData?.travel_type === "arrival") {
      defaultValues.date_of_arrival = travelData?.date_of_arrival;
      defaultValues.arrival_time = travelData?.arrival_time;
      defaultValues.arrival_location = travelData?.arrival_location;
    } else if (travelData?.travel_type === "departure") {
      defaultValues.date_of_departure = travelData?.date_of_departure;
      defaultValues.departure_time = travelData?.departure_time;
      defaultValues.departure_location = travelData?.departure_location;
    }
    reset(defaultValues);
    const rolesArray = travelData?.sub_category_type_id
      ?.map(Number)
      .map((id) => roles.find((item) => item.id === id))
      .filter(Boolean)
      .map((role) => ({ label: role.sub_category_name_view, value: role.id }));

    setSelectedRole(rolesArray || []);
  }, [travelData, reset, roles]);

  //

  const fetchTeamMembers = React.useCallback(
    async (travelType = "") => {
      try {
        const res = await dispatch(
          getTeamMember(genderValue, selectedRole, travelType)
        );
        const membersList =
          travelData?.member_ids
            ?.map(Number)
            ?.map((id) => {
              const player = res?.payload?.data?.find(
                (item) => item.player_detail_id === id
              );
              return player
                ? {
                    label: `${player.first_name} ${player.last_name}`,
                    value: player.player_detail_id,
                  }
                : null;
            })
            .filter(Boolean) || [];
        setSelectedMember(membersList);
      } catch (error) {
        console.error(error);
      }
    },
    [genderValue, selectedRole, dispatch, travelData]
  );

  useEffect(() => {
    selectedRole.length <= 0 && setSelectedMember([]);
    selectedRole.length > 0 && fetchTeamMembers();
  }, [genderValue, selectedRole, fetchTeamMembers]);

  //submit
  const onSubmit = async (data) => {
    if (isLoading) {
      return;
    }
    if (travel_type === "departure") {
      delete data.arrival_time;
      delete data.arrival_location;
      delete data.date_of_arrival;
    } else if (travel_type === "arrival") {
      delete data.departure_time;
      delete data.departure_location;
      delete data.date_of_departure;
    }

    const formData = new FormData();

    // Add all the fields except members and roles
    Object.keys(data).forEach((key) => {
      if (key === "file") {
        formData.append(key, data[key][0]);
      } else {
        formData.append(key, data[key]);
      }
    });

    // Append selected members as member_ids[0], member_ids[1], etc.
    selectedMember.forEach((member, index) => {
      formData.append(`member_ids[${index}]`, member.value);
    });

    // Append selected roles as sub_category_type_id[0], sub_category_type_id[1], etc.
    selectedRole.forEach((role, index) => {
      formData.append(`sub_category_type_id[${index}]`, role.value);
    });

    //unneccesary keys
    formData.delete("role");
    formData.delete("memberType");
    formData.append("gender_id", formData.get("gender"));
    formData.delete("gender");
    formData.delete("transportNumber");
    formData.delete("memberSize");
    let res = await dispatch(updateTravelData(formData, travelData?.id));
    if (res?.payload?.success) {
      Swal.fire({
        title: "",
        text: res?.payload?.message,
        icon: "success",
      });
      reset();
      setSelectedRole([]);
      setSelectedMember([]);
      closeEditTravelForm();
      dispatch(
        getTravellingPlans({ type: "refresh", page: +searchParams.get("page") })
      );
      sethandleError({});
    }
  };

  //
  function isValidMobileNumber(number) {
    // const MOBILE_NUMBER_REGEX = /^(?:\+91|91|0)?(?:6[3-9]|[7-9]\d)\d{8}$/;
    const MOBILE_NUMBER_REGEX = /^0?(?:6[3-9]|[7-9]\d)\d{8}$/;
    return MOBILE_NUMBER_REGEX.test(number);
  }
  function hasNoNumbers(value) {
    const NO_NUMBER_REGEX = /^[^0-9]*$/;
    return NO_NUMBER_REGEX.test(value);
  }
  const handleInputValidation = (e) => {
    const { name } = e.target;
    const { value } = e.target;

    if (name === "file") {
      const file = e.target.files[0];
      if (file) {
        if (
          file.type === "image/png" ||
          file.type === "application/pdf" ||
          file.type === "image/webp" ||
          file.type === "image/jpeg"
        ) {
          if (file.size <= 1048576) {
            clearErrors("file");
            sethandleError({ ...handleError, [name]: false });
          } else {
            setError("file", {
              type: "manual",
              message: "File size must not exceed 1MB",
            });
            sethandleError({ ...handleError, [name]: true });
          }
        } else {
          setError("file", {
            type: "manual",
            message: "Allowed formats: JPEG,PNG and PDF",
          });
          sethandleError({ ...handleError, [name]: true });
        }
      }
    } else {
      if (name === "poc_contact" && !isValidMobileNumber(value)) {
        setError(name, {
          type: "manual",
          message: "Invalid Number.",
        });
        sethandleError({ ...handleError, [name]: true });
      } else if (name === "poc_name" && !hasNoNumbers(value)) {
        setError(name, {
          type: "manual",
          message: "Not a valid name",
        });
        sethandleError({ ...handleError, [name]: true });
      }
      //  else if (
      //   name !== "department" &&
      //   PREVENT_SPECIAL_CHARACTER.test(value)
      // ) {
      //   setError(name, {
      //     type: "manual",
      //     message: "Special characters are not allowed.",
      //   });
      //   sethandleError({ ...handleError, [name]: true });
      // }
      else {
        clearErrors(name);
        sethandleError({ ...handleError, [name]: false });
      }
    }
  };

  const handleOpenFile = () => {
    const file = getValues("file");
    if (file && file.length > 0) {
      const fileURL = URL.createObjectURL(file[0]);
      window.open(fileURL, "_blank");
    } else {
      alert("No file uploaded.");
    }
  };

  //
  function optionOfMember(members) {
    let travelTypeValue = getValues("travel_type");

    //change the data type of ids
    let playerIds = travelData.member_ids.map(Number);
    const matchedArray = members.filter((item) =>
      playerIds.includes(item.player_detail_id)
    );
    let unmatchedArray = members.filter(
      (item) => !playerIds.includes(item.player_detail_id)
    );

    if (travelTypeValue === "arrival") {
      unmatchedArray = unmatchedArray.filter((ele) => {
        return ele?.arrival_booked === false;
      });
    } else if (travelTypeValue === "departure") {
      unmatchedArray = unmatchedArray.filter((ele) => {
        return ele?.departure_booked === false;
      });
    }

    return [...matchedArray, ...unmatchedArray].map((ele) => {
      return {
        label: `${ele.first_name} ${ele?.last_name} - ${ele?.kitd_unique_id}`,
        value: ele?.player_detail_id,
      };
    });
  }

  const arrivalPointValue = watch("city");

  return (
    <>
      <Modal
        className="modal-lg"
        show={showEditTravelForm}
        onHide={closeEditTravelForm}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="modal-header">
            <h4 className="modal-title fs-20">Travel Plan Form</h4>
            <button
              type="button"
              className="btn-close"
              onClick={closeEditTravelForm}
            >
              <span></span>
            </button>
          </div>

          <div className="modal-body">
            <div className="add-contact-box">
              <div className="add-contact-content">
                {/* Row 1 - Sport/Department and Role */}
                <div className="row">
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      Type <span className="text-danger">*</span>
                    </label>
                    <select
                      disabled
                      className="form-control"
                      {...register("travel_type", {
                        required: "Type is required",
                        onChange: (e) => fetchTeamMembers(e.target.value),
                      })}
                    >
                      <option value={""}>Type</option>
                      <option value={"arrival"}>Arrival</option>
                      <option value={"departure"}>Departure</option>
                    </select>

                    {errors.travel_type && (
                      <p className="text-danger">
                        {errors.travel_type.message}
                      </p>
                    )}
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      Sport/Department <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Sport/Department"
                      {...register("department", {
                        required: "Sport/Department is required",
                        onChange: handleInputValidation,
                        validate: (value) =>
                          value.trim() !== "" || "No spaces allowed",
                      })}
                    />
                    {errors.department && (
                      <p className="text-danger">{errors.department.message}</p>
                    )}
                  </div>
                </div>
                {/* Row 2 - Role and Gender */}
                <div className="row">
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      Role <span className="text-danger">*</span>
                    </label>
                    <MutliSelect
                      selected={selectedRole}
                      setSelected={setSelectedRole}
                      options={
                        userType === "national_sports_federation"
                          ? roles
                              .filter(
                                (ele) =>
                                  ele.sub_category_name ===
                                    "technical_officials" ||
                                  ele.sub_category_name ===
                                    "competition_manager" ||
                                  ele.sub_category_name === "dignitaries"
                              )
                              .map((ele) => ({
                                label: ele?.sub_category_name_view,
                                value: ele?.id,
                              }))
                          : roles.map((ele) => ({
                              label: ele?.sub_category_name_view,
                              value: ele?.id,
                            }))
                      }
                    />
                    {errors.sub_category_type_id && (
                      <p className="text-danger">
                        {errors.sub_category_type_id.message}
                      </p>
                    )}
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      Gender <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-control"
                      {...register("gender", {
                        required: "Gender is required",
                      })}
                    >
                      <option value="">Select Gender</option>
                      <option value="1">Male</option>
                      <option value="2">Female</option>
                      <option value="3">Both</option>
                      {/* <option value="4">Others</option> */}
                    </select>
                    {errors.gender && (
                      <p className="text-danger">{errors.gender.message}</p>
                    )}
                  </div>
                </div>
                {/* Row 3 - Member and Member Size */}
                <div className="row">
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      Member Manually <span className="text-danger">*</span>
                    </label>
                    <MutliSelect
                      selected={selectedMember}
                      setSelected={setSelectedMember}
                      options={
                        travel_type && Array.isArray(members)
                          ? optionOfMember(members)
                          : []
                      }
                    />
                    {errors.member_ids && (
                      <p className="text-danger">{errors.member_ids.message}</p>
                    )}
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      Member Size <span className="text-danger">*</span>
                    </label>
                    <input
                      {...register("memberSize")}
                      disabled
                      value={selectedMember?.length || 0}
                      type="text"
                      className="form-control"
                      placeholder="No. of member size"
                    />
                  </div>
                </div>
                {/* Row 4 - Date and Time */}
                <div className="row">
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      {travel_type === "departure"
                        ? "Date of Departure"
                        : "Date of Arrival"}{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      {...register(
                        travel_type === "departure"
                          ? "date_of_departure"
                          : "date_of_arrival",
                        {
                          required:
                            travel_type === "departure"
                              ? "Departure date is required"
                              : "Arrival date is required",
                        }
                      )}
                    />

                    {errors[
                      travel_type === "departure"
                        ? "date_of_departure"
                        : "date_of_arrival"
                    ] && (
                      <p className="text-danger">
                        {
                          errors[
                            travel_type === "departure"
                              ? "date_of_departure"
                              : "date_of_arrival"
                          ].message
                        }
                      </p>
                    )}
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      {travel_type === "departure"
                        ? "Departure Time"
                        : "Arrival Time"}{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="time"
                      className="form-control"
                      {...register(
                        travel_type === "departure"
                          ? "departure_time"
                          : "arrival_time",
                        {
                          required:
                            travel_type === "departure"
                              ? "Departure time is required"
                              : "Arrival time is required",
                          setValueAs: (value) => {
                            const hasSeconds =
                              /^([01]\d|2[0-3]):[0-5]\d:[0-5]\d$/.test(value);
                            return hasSeconds ? value : `${value}:00`;
                          },
                        }
                      )}
                    />
                    {errors[
                      travel_type === "departure"
                        ? "departure_time"
                        : "arrival_time"
                    ] && (
                      <p className="text-danger">
                        {
                          errors[
                            travel_type === "departure"
                              ? "departure_time"
                              : "arrival_time"
                          ].message
                        }
                      </p>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      Mode of Transportation
                      <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-control"
                      {...register("transportation_mode", {
                        required: "Transportation mode is required",
                      })}
                    >
                      <option value="">Select Mode</option>
                      <option value="FLIGHT">FLIGHT</option>
                      <option value="BUS">BUS</option>
                      <option value="TRAIN">TRAIN</option>
                      <option value="CAR">CAR</option>
                      {/* <option value="BY_OWN">BY OWN</option> */}
                      {/* <option value="OTHERS">OTHERS</option> */}
                    </select>
                    {errors.transportation_mode && (
                      <p className="text-danger">
                        {errors.transportation_mode.message}
                      </p>
                    )}
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      Train/Flight No./Bus No./Car No.
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter number"
                      {...register("vehical_no", {
                        required: "Transport Number is required",
                        onChange: handleInputValidation,
                        validate: (value) =>
                          value.trim() !== "" || "No spaces allowed",
                      })}
                    />
                    {errors.vehical_no && (
                      <p className="text-danger">{errors.vehical_no.message}</p>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      PNR Number
                      {/* <span className="text-danger">*</span> */}
                    </label>
                    <input
                      type="text"
                      maxLength={10}
                      className="form-control"
                      placeholder="PNR Number"
                      {...register("pnr_number", {
                        onChange: handleInputValidation,
                        // validate: (value) =>
                        //   value.trim() !== "" || "No spaces allowed",
                      })}
                    />
                    {errors.pnr_number && (
                      <p className="text-danger">{errors.pnr_number.message}</p>
                    )}
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      City
                      <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-control"
                      {...register("city", {
                        required: "City is required",
                      })}
                    >
                      <option value="">Select City</option>
                      {city.map((city) => {
                        return (
                          <option value={city.cityName}>
                            {city?.cityName}
                          </option>
                        );
                      })}
                    </select>
                    {errors.city && (
                      <p className="text-danger">{errors.city.message}</p>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      {travel_type === "departure"
                        ? "Departure Location"
                        : "Arrival Location"}
                      <span className="text-danger">*</span>
                    </label>

                    <select
                      className="form-control"
                      {...register(
                        travel_type === "departure"
                          ? "departure_location"
                          : "arrival_location",
                        {
                          required:
                            travel_type === "departure"
                              ? "Departure location is required"
                              : "Arrival location is required",
                          onChange: handleInputValidation,
                          validate: (value) =>
                            value.trim() !== "" || "No spaces allowed",
                        }
                      )}
                    >
                      <option value="">
                        {travel_type === "departure"
                          ? "Select departure location"
                          : "Select arrival location"}
                      </option>
                      {city
                        .filter((item) => item.cityName === arrivalPointValue) // Filter cities based on arrivalPointValue
                        .flatMap(
                          (
                            item // Use flatMap to directly extract and render BoardingPoints
                          ) =>
                            item.BoardingPoints.map((ele) => (
                              <option value={ele} key={ele}>
                                {ele}
                              </option>
                            ))
                        )}
                    </select>
                    {errors[
                      travel_type === "departure"
                        ? "departure_location"
                        : "arrival_location"
                    ] && (
                      <p className="text-danger">
                        {
                          errors[
                            travel_type === "departure"
                              ? "departure_location"
                              : "arrival_location"
                          ].message
                        }
                      </p>
                    )}
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      POC Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter name"
                      {...register("poc_name", {
                        required: "POC name is required",
                        onChange: handleInputValidation,
                        validate: (value) =>
                          value.trim() !== "" || "No spaces allowed",
                      })}
                    />
                    {errors.poc_name && (
                      <p className="text-danger">{errors.poc_name.message}</p>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      POC Contact <span className="text-danger">*</span>
                    </label>
                    <input
                      type="tel"
                      className="form-control"
                      placeholder="Enter contact number"
                      {...register("poc_contact", {
                        required: "POC contact is required",
                        onChange: handleInputValidation,
                        validate: (value) =>
                          value.trim() !== "" || "No spaces allowed",
                      })}
                    />
                    {errors.poc_contact && (
                      <p className="text-danger">
                        {errors.poc_contact.message}
                      </p>
                    )}
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>Upload Ticket</label>
                    <div className="input-group">
                      <input
                        type="file"
                        className="form-control"
                        {...register("file", {
                          onChange: handleInputValidation,
                        })}
                      />
                      <span className="input-group-text">
                        <i
                          style={{ cursor: "pointer" }}
                          onClick={handleOpenFile}
                          className="bi bi-eye"
                        ></i>
                      </span>
                    </div>
                    {errors.file && (
                      <p className="text-danger">{errors.file.message}</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal-footer">
            <Button variant="danger light" onClick={closeEditTravelForm}>
              Close
            </Button>
            <Button
              type="submit"
              variant="primary"
              disabled={
                Object.values(handleError).some((value) => value === true) ||
                selectedMember.length <= 0 ||
                selectedRole.length <= 0
              }
            >
              {isLoading ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />{" "}
                  Loading...
                </>
              ) : (
                "Save changes"
              )}
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
}

export default React.memo(EditTravelPlan);

//end
